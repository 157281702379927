<template>
  <div v-show="showOffcanvas">
    <div :class="['offcanvas offcanvas-right offcanvas-custom', { 'offcanvas-on' : show, 'offcanvas-off' : !show }]">
      <b-form class="d-flex flex-column h-100"
              @submit.stop.prevent="confirm">

        <div class="d-flex flex-column h-100">
          <!--begin::Header-->
          <div class="offcanvas-header d-flex align-items-center justify-content-between p-5">
            <h3 class="font-weight-bold m-0">
              {{ $ml.get('createNewTeam')}}
            </h3>
            <a href="#"
               class="d-flex btn btn-xs btn-light p-2 rounded-sm sidebar_close"
               @click="close">
              <i class="far fa-times-circle p-0"></i>
            </a>
          </div>
          <!--end::Header-->

          <div :disabled="loading">
            <div class="offcanvas-content pt-10 px-5">
              <perfect-scrollbar class="offcanvas-content pr-5 mr-n5 scroll"
                                 style="height: calc(100vh - 200px); position: relative">

                <div class="row">
                  <div class="col-12">
                    <b-form-group id="nameGroup"
                                  :label="$ml.get('name')"
                                  label-for="name">
                      <b-form-input id="name"
                                    v-model="form.name"
                                    :placeholder="$ml.get('name')"
                                    :state="validation"></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </perfect-scrollbar>
            </div>
            <div class="mt-auto offcanvas-content pb-5 px-5">
              <div class="row">
                <div class="col-6 text-center">
                  <b-button type="reset"
                            class="w-100"
                            variant="danger"
                            @click="close">{{$ml.get('cancel')}}</b-button>

                </div>
                <div class="col-6 text-center">
                  <b-button type="submit"
                            class="w-100"
                            variant="primary"
                            ref="create_team">{{$ml.get('save')}}</b-button>
                </div>
              </div>

            </div>
          </div>
        </div>

      </b-form>

    </div>
    <div class="offcanvas-overlay"
         v-if="show"
         @click="close"></div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import EventBus from '@/event-bus'

export default {
  name: 'CreateTeamModal',
  props: ['selectedItems'],
  data() {
    return {
      showOffcanvas: false,
      form: {
        name: '',
      },
      loading: false,
      validation: null,
      show: false,
    }
  },
  mounted() {
    EventBus.$on('openCreateTeamModal', (payload) => {
      this.show = payload
    })
  },
  created() {
    this.setShowOffcanvas()
  },
  computed: {
    isEdit() {
      return !!this.selectedItems
    },
  },
  methods: {
    setShowOffcanvas() {
      setTimeout(() => {
        this.showOffcanvas = true
      }, 1000)
    },
    confirm() {
      this.loading = true
      const submitButton = this.$refs['create_team']
      this.$spinnerLoader.addSpinnerLoader(submitButton)

      let type = this.isEdit ? 'put' : 'post'
      let url = `teams${this.isEdit ? `/${this.selectedItems.id}` : ''}`

      let params = {
        name: this.form.name,
      }

      ApiService[type](url, params)
        .then((res) => {
          this.loading = false
          this.$spinnerLoader.removeSpinnerLoader(submitButton)
          this.close()
          this.$generateToast(
            this,
            'success',
            this.isEdit
              ? this.$ml.get('updatedTeamSuccess')
              : this.$ml.get('addedTeamSuccess')
          )
          this.$emit('getTeams')
        })
        .catch((err) => {
          this.loading = false
          this.$spinnerLoader.removeSpinnerLoader(submitButton)
          this.$generateToast(
            this,
            'danger',
            this.$ml.get('anErrorHasOccurred')
          )
        })
    },
    close() {
      this.show = false
      this.$emit('close')
    },
  },
  watch: {
    selectedItems(newVal) {
      if (newVal) this.form.name = newVal.name
      else this.form.name = ''
    },
  },
}
</script> 
