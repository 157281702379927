<template>
  <div>
    <b-card no-body
            class="support-filter p-5 mb-5">
      <div class="d-flex align-items-center justify-content-between"
           :disabled="loading">
        <div class="search-input ml-2">
          <v-text-field class="my-0 font-size-sm multiselect-w"
                        :placeholder="$ml.get('search')"
                        prepend-icon="search"
                        v-model="search"
                        @keyup="searchKeyup"
                        @blur="searchBlur"
                        single-line
                        dense
                        flat
                        solo
                        hide-details></v-text-field>
        </div>

        <b-dropdown id="dropdown-action"
                    variant="filter-success"
                    :text="$ml.get('dateFilter')"
                    class="mobile-100"
                    ref="dateFilterDropdown"
                    right>
          <b-dropdown-form>
            <b-form-group :label="$ml.get('startDate')"
                          label-for="dropdown-form-startdate"
                          @submit.stop.prevent>
              <b-form-input id="dropdown-form-startdate"
                            size="sm"
                            v-model="startDateFilter"
                            v-mask="['##/##/####']"
                            :placeholder="$ml.get('enterStartDate')"></b-form-input>
            </b-form-group>

            <b-form-group :label="$ml.get('endDate')"
                          label-for="dropdown-form-enddate"
                          @submit.stop.prevent>
              <b-form-input id="dropdown-form-enddate"
                            size="sm"
                            v-model="endDateFilter"
                            v-mask="['##/##/####']"
                            :placeholder="$ml.get('enterEndDate')"></b-form-input>
            </b-form-group>
            <div class="d-flex mt-5">
              <a href="#"
                 class="d-flex btn btn-sm rounded-sm pr-15 pl-15 mr-5 btn-danger"
                 @click="dateFilterClear()">
                {{ $ml.get('clear') }}
              </a>
              <a href="#"
                 @click="dateFilterSubmit()"
                 class="d-flex btn btn-sm rounded-sm pr-15 pl-15 btn-primary">
                {{ $ml.get('search') }}
              </a>
            </div>
          </b-dropdown-form>
        </b-dropdown>
      </div>
    </b-card>

    <Teams :search="searchInput"
           :dateFilter="dateFilter"></Teams>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Teams from '@/components/teams/Teams'

export default {
  name: 'TeamManagement',
  data() {
    return {
      loading: false,
      search: '',
      searchInput: '',
      startDateFilter: '',
      endDateFilter: '',
      dateFilter: {},
      resetPage: false,
    }
  },
  mounted() {
    this.setBreadcrumb()

    this.$ml.bus.$on('vueml-language-changed', (payload) => {
      this.setBreadcrumb()
    })
  },
  methods: {
    setBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$ml.get('teamManagement') },
      ])
    },
    dateFilterClear() {
      this.resetPage = true
      this.startDateFilter = ''
      this.endDateFilter = ''
    },
    dateFilterSubmit() {
      this.resetPage = true
      this.$refs.dateFilterDropdown.hide(true)
      // actios here
      this.dateFilter = {
        startDate: this.startDateFilter,
        endDate: this.endDateFilter,
      }
    },
    searchKeyup(e) {
      if (e.keyCode === 13) {
        this.searchBlur()
      }
    },
    searchBlur() {
      this.searchInput = this.search
    },
  },
  components: {
    Teams,
  },
}
</script>

<style lang="scss">
.support-filter {
  #dropdown-action {
    .btn.dropdown-toggle {
      border-radius: 0.28rem;
    }
  }
  .dropdown-item {
    padding: 0.25rem 1.25rem;
  }
  .small {
    padding-left: 0.5rem;
  }
  .b-dropdown-form {
    min-width: 320px;
  }
  .search-input {
    border: 1px solid #e5e5e5;
    border-radius: 0.28rem;
    padding-left: 6px;
  }
}
</style>
