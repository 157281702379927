<template>
  <Card class="teams-list">
    <CardLoader v-if="loading">
      <rect x="0"
            y="4"
            width="400"
            height="12"
            rx="3" />
      <rect x="0"
            y="24"
            width="150"
            height="12"
            rx="3" />
      <rect x="0"
            y="56"
            width="400"
            height="12"
            rx="3" />
      <rect x="0"
            y="76"
            width="370"
            height="12"
            rx="3" />
      <rect x="0"
            y="96"
            width="178"
            height="12"
            rx="3" />
    </CardLoader>

    <template #header>
      <div class="row w-100 p-0 m-0">
        <div class="title col-md-6">
          <h3 class="text-dark font-size-secondary m-0">
            {{ $ml.get('teams') }}
          </h3>
        </div>
        <div class="col-md-6 text-right card-buttons"
             v-if="!loading">
          <b-button variant="danger"
                    size="sm"
                    class="rounded-sm"
                    v-if="teams.length > 0 && itemIds.length > 0"
                    @click="deleteSelectedTeams()">{{ $ml.get('deleteSelected') }}
          </b-button>

          <b-button variant="primary"
                    size="sm"
                    class="rounded-sm ml-2"
                    @click="openDetail">{{ $ml.get('createNewTeam') }}</b-button>
        </div>
      </div>
    </template>

    <template v-if="!loading">
      <v-data-table :headers="headers"
                    :items="teams"
                    :options.sync="options"
                    :items-per-page="100"
                    :server-items-length="100"
                    :loading="loading"
                    :hide-default-footer="true"
                    @click:row="handleRowClick"
                    id="team-list">
        <!-- :mobile-breakpoint="0"  -->

        <template v-slot:header.choose>
          <label class="checkbox">
            <input type="checkbox"
                   v-model="checked"
                   @change="checkAll" />
            <span></span>
          </label>
        </template>

        <template v-slot:header.action>
          {{$ml.get('action')}}
        </template>

        <template v-slot:item.choose="{ item }">
          <label class="checkbox">
            <input type="checkbox"
                   v-model="itemIds"
                   :value="item.id" />
            <span></span>
          </label>
        </template>

        <template v-slot:item.action="{ item }"
                  class="p-0 actionsButton">
          <ButtonEdit class="mr-2"
                      :class="!item.is_valid ? 'cursor-not-change' : ''"
                      @click.native="editTeam(item)"></ButtonEdit>
          <ButtonDelete @click.native="deleteTeam(item)"></ButtonDelete>
        </template>
      </v-data-table>
    </template>

    <CreateTeamModal :selectedItems="selectedItems"
                     @getTeams="getTeams"
                     @close="closeModal"></CreateTeamModal>
  </Card>
</template>

<script>
import ApiService from '@/core/services/api.service'
import moment from 'moment'
import Card from '@/components/Card'
import ButtonEdit from '@/components/buttons/ButtonEdit'
import ButtonDelete from '@/components/buttons/ButtonDelete'
import CardLoader from '@/view/content/CardLoader'
import EventBus from '@/event-bus'
import CreateTeamModal from '@/components/teams/CreateTeamModal'

export default {
  props: {
    search: String | Number,
    dateFilter: Object,
  },
  name: 'Teams',
  data() {
    return {
      loading: false,
      checked: false,
      itemIds: [],
      teams: [],
      options: {},
      selectedItems: '',
      headers: [
        { text: '', value: 'choose', sortable: false, width: '40px' },
        {
          text: this.$ml.get('teamName'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$ml.get('actions'),
          value: 'action',
          sortable: false,
          align: 'right',
        },
      ],
      itemIdsProcessCount: 0,
    }
  },
  created() {
    this.getTeams()
  },
  methods: {
    getTeams() {
      this.getTeamsData()
        .then((res) => {
          this.setTeamsData(res)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getTeamsData() {
      this.loading = true
      return new Promise((resolve, reject) => {
        let url = `teams?position=created_at|DESC`

        if (this.search != '') {
          url += `&name=${this.search}`
        }

        if (this.dateFilter && this.dateFilter.startDate != '') {
          url += `&start=${moment(
            this.dateFilter.startDate,
            'MM/DD/YYYY'
          ).format('YYYY-MM-DD')}`
        }

        if (this.dateFilter && this.dateFilter.endDate != '') {
          url += `&end=${moment(this.dateFilter.endDate, 'MM/DD/YYYY').format(
            'YYYY-MM-DD'
          )}`
        }

        ApiService.get(url)
          .then(({ data }) => {
            this.loading = false
            resolve(data)
          })
          .catch((err) => {
            this.loading = false
            reject(err)
          })
      })
    },
    setTeamsData(res) {
      this.teams = res.data
    },
    checkAll() {
      this.itemIds = []
      if (this.checked) {
        for (const i in this.teams) {
          this.itemIds.push(this.teams[i].id)
        }
      }
    },
    deleteTeam(team) {
      this.$bvModal
        .msgBoxConfirm(this.$ml.get('doYouWantToDeleteSelectedTeams'), {
          title: this.$ml.get('pleaseConfirm'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$ml.get('yes'),
          cancelTitle: this.$ml.get('no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true
            ApiService.delete(`teams/${team.id}`)
              .then(() => {
                this.getTeams()
                this.loading = false
                this.generateToast(
                  'success',
                  this.$ml.get('deletedTeamSuccess')
                )
              })
              .catch((err) => {
                this.loading = false

                if (err == 'Error') {
                  this.generateToast(
                    'danger',
                    this.$ml.get('anErrorHasOccurred')
                  )
                }
              })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    deleteSelectedTeams() {
      if (this.itemIds.length === 0) {
        this.generateToast('danger', this.$ml.get('noSelection'))
      } else {
        this.$bvModal
          .msgBoxConfirm(this.$ml.get('doYouWantToDeleteSelectedTeams'), {
            title: this.$ml.get('pleaseConfirm'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: this.$ml.get('yes'),
            cancelTitle: this.$ml.get('no'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.loading = true
              for (const i in this.itemIds) {
                ApiService.delete(`teams/${this.itemIds[i]}`)
                  .then((res) => {
                    // console.log(res)
                    // this.generateToast(
                    //   'success',
                    //   this.$ml.get('deletedCreditCardSuccess')
                    // )
                  })
                  .catch((err) => {
                    // if (err == 'Error') {
                    //   this.generateToast(
                    //     'danger',
                    //     this.$ml.get('anErrorHasOccurred')
                    //   )
                    // }
                  })
                  .finally(() => {
                    this.itemIdsProcessCount++
                  })
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    generateToast(variant, text) {
      this.$bvToast.toast(text, {
        title: `${
          variant == 'success' ? this.$ml.get('success') : this.$ml.get('error')
        }`,
        variant: variant,
        solid: true,
      })
    },
    openDetail() {
      EventBus.$emit('openCreateTeamModal', true)
    },
    editTeam(item) {
      this.selectedItems = item
      EventBus.$emit('openCreateTeamModal', true)
    },
    closeModal() {
      this.selectedItems = ''
    },
    handleRowClick(item) {
      this.$router.push(`/team-management/${item.id}`)
    },
  },
  watch: {
    itemIdsProcessCount(value) {
      if (this.itemIds.length == value) {
        this.itemIdsProcessCount = 0
        this.loading = false
        this.getTeams()
      }
    },
    itemIds() {
      if (this.itemIds.length == this.teams.length) this.checked = true
      else this.checked = false
    },
    search() {
      this.getTeams()
    },
    dateFilter() {
      this.getTeams()
    },
  },
  components: {
    Card,
    ButtonEdit,
    ButtonDelete,
    CardLoader,
    moment,
    CreateTeamModal,
  },
}
</script>
